<template>
  <div class="all">
          <nav-bar-back class="detail-nav">
    <div class="title" slot="title">
     <div slot="center">借支详情</div>
    </div>
  </nav-bar-back>
  
  <div class="header"  :style="{backgroundImage:'url(\''+BorrowStatu(statusCode)+'\')'}">

      <p >借支金额</p>
      <p><span>￥</span>100.00</p>
  </div>
  
  <van-cell  >
        <div class="contentItem">
  <p>申请时间</p>
  <p>{{time}}</p>
        </div>
    </van-cell>
     
  <van-cell  >
        <div class="contentItem">
  <p>姓名</p>
  <p>{{name}}</p>
        </div>
    </van-cell>

 
  <van-cell  >
        <div class="contentItem">
  <p>身份证</p>
  <p>{{idCard}}</p>
        </div>
    </van-cell>

  <van-cell  >
        <div class="contentItem">
  <p>在职部门</p>
  <p>{{depName}}</p>
        </div>
    </van-cell>


  <van-cell  >
        <div class="contentItem">
  <p>工号</p>
  <p>{{wordId}}</p>
        </div>
    </van-cell>

<van-cell  v-if="bankName">
        <div class="contentItem">
  <p>银行名称</p>
  <p>{{bankName}}</p>
        </div>
    </van-cell>

  <van-cell  v-if="bankCode">
        <div class="contentItem">
  <p>银行卡号</p>
  <p>{{bankCode}}</p>
        </div>
    </van-cell>

   
   <van-cell v-if="wxCode" >
        <div class="contentItem">
  <p>微信号</p>
  <p>{{wxCode}}</p>
        </div>
    </van-cell>
   
 

 
  <van-button  v-if="statusCode==0" class="commit" @click="commit">取消申请</van-button>
   
   </div>
</template>

<script>
import NavBarBack from 'common/navbar/NavBarBack'
import {detail,cancel} from "network/apply";
// 
import { Cell, CellGroup,Field,Button,DatetimePicker,Popup ,Picker, Toast} from 'vant';
export default {
    computed: {
         BorrowStatu(){
             return function(status){
                 var status2;
                 switch(status){
                     case -2:
                         status2= require('@/assets/images/borrow/canced.png')
                         break;
                          case -1:
                              status2= require('@/assets/images/borrow/error.png')
                         break;
                          case 0:
                              status2= require('@/assets/images/borrow/applying.png')
                         break;
                          case 1:
                              status2= require('@/assets/images/borrow/success.png')
                         break;
                          case 2:
                              status2= require('@/assets/images/borrow/end.png')
                         break;

                 }
                 return status2
             }
         }  
    },
    data () {
        return {
         img:require('@/assets/images/borrow/success.png'),
          id:'',
          status:false,
            name:'',
            phone:'',
            idCard:'',
            compare:'',
            depName:'',
            wordId:'',
            money:'',
            time:'',
            bankName:'',
            bankCode:'',
            res:'',
            type:'',
            wxCode:'',
            statusCode:null
        }
    },
       created () {
            this.id=this.$route.query.id
             detail(this.id).then(res=>{
                  this.res=res
                   this.name=res.user.name
                   this.money=res.money;
                   this.time=res.created_at
                   this.type=res.type
                   this.idCard=res.user.id_card
                   this.bankName=res.bank_name
                   this.bankCode=res.bank_cart
                   this.depName=res.user.department
                   this.wordId=res.user.work_number
                   this.statusCode=res.status
                   if(this.type==1){
                  this.wxCode=res. weixin
                   }
                   
            //  console.log(res,'ggg')
             })

    },
   components: {
         NavBarBack,
         [Field.name]:Field,
         [CellGroup.name]:CellGroup,
         [Button.name]:Button,
         [Cell.name]:Cell
       
   },
   
   methods: {
  commit(){
   
          
       cancel(this.id).then(res=>{
           if(res=='取消成功'){
               Toast.success('取消成功')
              this.statusCode=-2
           }
               
           
       })
  }
       
   },
 

}
</script>

<style scoped>
.header :first-child{
    font-size: 13px;
}
.header p{

    font-size: 22px;
}
.header span{
     font-size: 20px;
}
.contentItem{
      display: flex;
    justify-content: space-between
}
.commit{
    width: 100%;
    background-color: rgb(223, 223, 223);
     /* background-image: linear-gradient(to right, rgb(243, 70, 31) , rgb(243, 40, 71)); */
    position: fixed;
    color: #333;
    bottom: 0;
    left: 0;
    border: none;
    /* background-color: red; */
}


.all{
    width: 100%
}
.content{
    width: 100%;
    margin-bottom: 60px;
}
.header{
    padding-top: 15px;
    padding-left: 20px;
    line-height: 1.6;
    height: 90px;
    color: #f0f0f0;
    background-repeat: no-repeat;
    background-size: 100%;
   
    
}
</style>
// 